import {
  Button,
  FormControl,
  Grid,
  IconButton,
  TextField
} from '@material-ui/core';
import { Remove } from '@material-ui/icons';
import { DataGrid } from '@material-ui/data-grid';
import React from 'react';
import BarberAvatar from '../../../images/barber.jpeg';

const SalonsConsultantsForm = ({ createData, setCreateData }) => {
  const defaultFormData = {
    name: '',
    color: '#000000',
    picture: ''
  };
  const [selected, setSelected] = React.useState([]);
  const [formData, setFormData] = React.useState(defaultFormData);

  React.useEffect(() => {
    if (createData.consultants) {
      setSelected(
        createData.consultants.map((c) => ({ ...c, id: Math.random() }))
      );
      return;
    }
    setSelected([]);
  }, [createData]);

  const add = () => {
    const newConsultants = [...selected, { id: Math.random(), ...formData }];
    setCreateData((d) => ({ ...d, experts: newConsultants }));
    setFormData(defaultFormData);
  };

  const remove = (id) => {
    const newConsultants = selected.filter((c) => c._id !== id);
    setCreateData((d) => ({ ...d, experts: newConsultants }));
  };

  const columns = [
    {
      field: 'picture',
      headerName: 'Photo',
      width: 150,
      renderCell: ({ values }) =>
        values ? (
          <img src={values} alt="consultants-pic" />
        ) : (
          <img
            src={BarberAvatar}
            alt="consultants-pic"
            style={{ objectFit: 'cover', width: 50 }}
          />
        )
    },
    {
      field: 'name',
      headerName: 'Nom',
      flex: 1
    },
    {
      field: 'color',
      headerName: 'Couleur',
      flex: 1,
      renderCell: ({ row }) => (
        <span
          style={{
            color: row.color,
            fontWeight: 700
          }}
        >
          {row.color}
        </span>
      )
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: ({ row }) => (
        <IconButton onClick={() => remove(row._id)}>
          <Remove color="error" />
        </IconButton>
      )
    }
  ];

  return (
    <Grid container flexDirection="column" height={500} rowGap={2}>
      <Grid item container rowGap={1}>
        <Grid item container columnGap={1}>
          <FormControl sx={{ flex: 1 }}>
            <TextField
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              fullWidth
              label="Nom"
              placeholder="Nom"
            />
          </FormControl>
          <FormControl sx={{ width: 150 }}>
            <TextField
              value={formData.color}
              onChange={(e) =>
                setFormData({ ...formData, color: e.target.value })
              }
              fullWidth
              label="Couleur"
              placeholder="Couleur"
              type="color"
            />
          </FormControl>
        </Grid>
        <FormControl fullWidth>
          <TextField
            value={formData.picture}
            onChange={(e) =>
              setFormData({ ...formData, picture: e.target.value })
            }
            fullWidth
            label="Lien photo"
            placeholder="Lien photo"
          />
        </FormControl>
        <FormControl fullWidth>
          <Button
            onClick={add}
            fullWidth
            variant="outlined"
            style={{ height: 55 }}
            disabled={!formData.name || !formData.picture || !formData.color}
          >
            Ajouter
          </Button>
        </FormControl>
      </Grid>
      <DataGrid
        rows={selected}
        columns={columns}
        hideFooter
        style={{ height: '100%' }}
      />
    </Grid>
  );
};

export default SalonsConsultantsForm;
