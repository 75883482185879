import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  DialogTitle,
  FormHelperText,
  FormControl
} from '@material-ui/core';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import { useOurPix } from 'src/contexts/OurPixContext';
import { Formik } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as yup from 'yup';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';



const  OurPixModal = ({
  open,
  handleClose,
  handleSubmit,
  title,
  salons,
  buttonTitle
}) => {
  const { ourPix } = useOurPix();
  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [idSalon, setIdSalon] = React.useState();

   const handleChangeSelect = (event) => {
     setIdSalon(event.target.value);
   };


  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{title}</DialogTitle>

      <Formik
        enableReintialize={{ enableReinitialize: true }}
        initialValues={{
          salon: ourPix?.salon || ''
        }}
        validationSchema={yup.object().shape({
          // nameEntreprise: yup.string().required('Nom entrerprise obligatoire.')
          //  startingDate: yup.date().required('Champ obligatoire.'),
          //  endingDate: yup.date().required('Champ obligatoire.')
          // status: yup.string().required('Champ obligatoire.'),
        })}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit: onSubmit, errors, values, setFieldValue }) => (
          <form onSubmit={onSubmit}>
            <DialogContent>
              <FormControl fullWidth error={!!errors.name}>
                <InputLabel id="demo-simple-select-label">
                  Choisir un salon
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={idSalon}
                  label="salonId"
                  autoFocus
                  onChange={(e) => {
                    handleChangeSelect(e);
                    console.log(e.target.value);
                    setFieldValue('salon', e.target.value);
                  }}
                >
                  {salons.map((salon, index) => (
                    <MenuItem value={salon._id}>{salon.name}</MenuItem>
                  ))}
                </Select>
                <br />
                {!!errors && !!errors.name && (
                  <FormHelperText sx={{ marginLeft: 0.5 }}>
                    {errors.name}
                  </FormHelperText>
                )}
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleClose();
                }}
              >
                Annuler
              </Button>
              <Button onClick={onSubmit}>{buttonTitle}</Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default OurPixModal;
