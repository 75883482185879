import React from 'react';
import { DEFAULT_ERROR_MESSAGE } from 'src/utils/constants';
import toastMessage from 'src/utils/toast';
import axios from '../utils/axios';

const SalonsContext = React.createContext({});

export const SalonsProvider = ({ children }) => {
  const [salons, setSalons] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [fetching, setFetching] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [pageSize] = React.useState(10);
  const [search, setSearch] = React.useState('');

  const [toDelete, setToDelete] = React.useState(null);

  const [createStep, setCreateStep] = React.useState(0);

  const fetchSalons = async (searchQuery = '', currentPage = 0) => {
    try {
      setFetching(true);
      const { data } = await axios.get(
        `/v1/admin/salons?page=${currentPage}${`&search=${searchQuery}`}&limit=${pageSize}`
      );
      setFetching(false);
      setSalons(data.results);
      setTotalCount(data.totalResults);
    } catch (error) {
      setFetching(false);
      toastMessage(
        error?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };
  const getAllSalons = async () => {

    try {
      setFetching(true);
      const { data } = await axios.get(
        `/v1/admin/salons`
      );
        
      return data.results;
    } catch (error) {
     
     
      toastMessage(
        error?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
      return []
    }
  };
  const createSalon = async (createData) => {
    try {
      const { data } = await axios.post('/v1/admin/salons', createData);
      setCreateStep(0);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const fetchSalon = async (id) => {
    try {
      const { data } = await axios.get(`/v1/admin/salons/${id}`);

      return Promise.resolve(data.salon);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const toggleCertified = async (id, isCertified) => {
    try {
      await axios.put('/v1/admin/salons', {
        id,
        isCertified
      });
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const updateSalon = async (id, payload) => {
    try {
      const { data } = await axios.put('/v1/admin/salons', { id, ...payload });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const deleteSalon = async () => {
    if (!toDelete) return Promise.resolve(false);
    try {
      await axios.delete('/v1/admin/salons/', {
        data: { id: toDelete._id }
      });
      if (search) {
        fetchSalons(search, page);
      } else {
        fetchSalons('', page);
      }
      setToDelete(null);
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  React.useEffect(() => {
    fetchSalons();
  }, []);

  React.useEffect(() => {
    if (search) {
      fetchSalons(search, page);
      return;
    }
    fetchSalons('', page);
  }, [search]);

  React.useEffect(() => {
    if (page !== 0) {
      fetchSalons('', page + 1);
    }
  }, [page]);

  return (
    <SalonsContext.Provider
      value={{
        salons,
        fetching,
        fetchSalons,
        page,
        setPage,
        pageSize,
        totalCount,
        search,
        setSearch,
        createStep,
        setCreateStep,
        createSalon,
        toDelete,
        setToDelete,
        fetchSalon,
        getAllSalons,
        toggleCertified,
        updateSalon,
        deleteSalon
      }}
    >
      {children}
    </SalonsContext.Provider>
  );
};

export const useSalons = () => React.useContext(SalonsContext);
