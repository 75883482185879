import { toast } from 'react-toastify';

const toastMessage = (message, type) => {
  switch (type) {
    case 'info':
      return toast.info(message);
    case 'error':
      return toast.error(message);
    case 'success':
      return toast.success(message);
    case 'warning':
      return toast.warn(message);
    default:
      return toast.info(message);
  }
};

export default toastMessage;
