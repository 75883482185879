import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import Salons from './Salons';
import Salon from './Salon';


const index = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={match.path} component={Salons} />
      <Route path={`${match.path}/:id`} component={Salon} />
      <Redirect to={match.path} />
    </Switch>
  );
};

export default index;
