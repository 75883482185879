import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import { KpisProvider, useKpis } from 'src/contexts/KpiContext';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const Kpis = () => {
  const { numberSalons, numberClients, numberAppointements } = useKpis();
  const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );

  return (
    <>
      <Helmet>
        <title>Ads | Izya</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Typography variant="h2" color="text.primary" gutterBottom>
                    Salon
                  </Typography>
                  <Typography variant="h3">{numberSalons}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Typography variant="h2" color="text.primary" gutterBottom>
                    Client
                  </Typography>
                  <Typography variant="h3">{numberClients}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Typography variant="h2" color="text.primary" gutterBottom>
                    Appointement
                  </Typography>
                  <Typography variant="h3">{numberAppointements}</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default () => (
  <KpisProvider>
    <Kpis />
  </KpisProvider>
);
