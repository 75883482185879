import axios from 'axios';

// Request interceptor for API calls
axios.interceptors.request.use(
  async (config) => {
    let keys = localStorage.getItem('izya_auth');
    if (keys) {
      keys = JSON.parse(keys);
      config.headers = {
        Authorization: `Bearer ${keys.access.token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      localStorage.removeItem('izya_auth');
      alert('Session expired, Please sign in again');
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

export default axios;
