import React from 'react';
import { Card, Chip, IconButton } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { useAppointement } from 'src/contexts/AppointementContext';
import Empty from 'src/assets/Empty';

const AppointmentsResults = () => {
  const { getAppointements, page, setPage, fetching, pageSize, totalCount } =
    useAppointement();
  const { id } = useParams();
  const [list, setList] = React.useState([]);

  const getName = (client) => {
    if (client.fullName) {
      return client.fullName;
    }

    if (!client.firstName && !client.lastName) {
      return '-';
    }

    return `${client.firstName} ${client.lastName}`;
  }

  React.useEffect(() => {
    getAppointements(id)
      .then((data) => {
        setList(data.results);
      })
  }, []);

  const columns = [
    {
      field: 'salon',
      headerName: 'Nom salon',
      valueFormatter: ({ value }) => value.name,
      flex: 1
    },
    {
      field: 'salonSubService',
      headerName: 'prestations',
      width: 150,
      renderCell: (params) => (
        <ul className="flex">
          {params.value.map((role, index) => (
            <li>{role.subService.name}</li>
          ))}
        </ul>
      ),
      type: 'string',
      flex: 2
    },
    {
      field: 'client',
      headerName: 'Nom client',
      valueFormatter: ({ value }) => getName(value),
      flex: 1
    },
    {
      field: 'totalAmount',
      headerName: 'Prix totale',
      flex: 1
    },
    {
      field: 'date',
      headerName: 'Date debut',
      flex: 1
    },
    {
      field: 'endingDate',
      headerName: 'Date fin',
      flex: 1
    }
  ];
  return (
    <Card style={{ width: '100%', height: '60vh' }}>
      {list.length === 0 ? (
        <Empty label="Aucun rendez vous !" />
      ) : (
        <DataGrid
          rows={list}
          columns={columns}
          pageSize={pageSize}
          page={page}
          onPageChange={(p) => setPage(p)}
          paginationMode="server"
          rowCount={totalCount}
          loading={fetching}
          getRowId={(row) => row._id || row.id}
        />
      )}
    </Card>
  );
};

export default AppointmentsResults;
