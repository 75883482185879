import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { ThemeProvider, StyledEngineProvider } from '@material-ui/core';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import Login from './pages/Login';
import DashboardLayout from './components/DashboardLayout';
import PrivateRoute from './components/PrivateRoute';
import 'react-toastify/dist/ReactToastify.css';


const App = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router>
        <Switch>
          <PrivateRoute path="/app" component={DashboardLayout} />
          <Route path="/signin" component={Login} />
          <Redirect exact from="/" to="/app" />
        </Switch>
      </Router>
    </ThemeProvider>
    <ToastContainer
      position="top-center"
      pauseOnFocusLoss={false}
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
    />
  </StyledEngineProvider>
);

export default App;
