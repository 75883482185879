import React from 'react';
import { Tabs, Tab, Typography, Box, Card, Button } from '@mui/material';
import { Check, Close } from '@material-ui/icons';
import SalonDetailsForm from 'src/components/salons/SalonForms/SalonDetailsForm';
import { SalonsProvider, useSalons } from 'src/contexts/SalonsContext';
import { useParams } from 'react-router-dom';
import toastMessage from 'src/utils/toast';
import { DEFAULT_ERROR_MESSAGE } from 'src/utils/constants';
import _ from 'lodash';
import SalonsSpecialtiesForm from 'src/components/salons/SalonForms/SalonsSpecialtiesForm';
import { SpecialtyProvider } from 'src/contexts/SpecialtyContext';
import SalonsConsultantsForm from 'src/components/salons/SalonForms/SalonsConsultantsForm';
import SalonsLocalizationForm from 'src/components/salons/SalonForms/SalonsLocalizationForm';
import SalonsSocialMediasForm from 'src/components/salons/SalonForms/SalonsSocialMediasForm';
import SalonMediaForm from 'src/components/salons/SalonForms/SalonMediaForm';
import { Helmet } from 'react-helmet';
import SalonOpenDatesForm from 'src/components/salons/SalonForms/SalonOpenDatesForm';
import wilayasJSON from '../../assets/wilayas.json';

const Salon = () => {
  const [salon, setSalon] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(0);

  const { fetchSalon, updateSalon } = useSalons();
  const { id } = useParams();

  React.useEffect(() => {
    setLoading(true);
    fetchSalon(id)
      .then((data) => {
        setLoading(false);
        setSalon(data);
      })
      .catch((err) => {
        toastMessage(
          err.response?.data?.message || DEFAULT_ERROR_MESSAGE,
          'error'
        );
        setLoading(false);
      });
  }, []);

  const [editDetails, setEditDetails] = React.useState(false);
  const detailsFormRef = React.useRef(null);

  const handleDetailsSubmit = async (details) => {
    try {
      if (_.isEqual(details, salon)) {
        setEditDetails(false);
        return;
      }
      await updateSalon(id, details);
      setSalon((salonData) => ({ ...salonData, ...details }));
      setEditDetails(false);
      toastMessage('Changements sauvegardé avec succès !', 'success');
    } catch (error) {
      toastMessage(
        error.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };

  const handleSpecialtiesSubmit = async () => {
    try {
      await updateSalon(id, {
        specialities: salon.specialities.map((s) => s._id)
      });
      toastMessage('Changements sauvegardé avec succès !', 'success');
    } catch (error) {
      toastMessage(
        error.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };

  const handleConsultantsSubmit = async () => {
    try {
      await updateSalon(id, {
        consultants: salon.consultants
      });
      toastMessage('Changements sauvegardé avec succès !', 'success');
    } catch (error) {
      toastMessage(
        error.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };

  const [editLocalization, setEditLocalization] = React.useState(false);
  const localizationFormRef = React.useRef(null);

  const handleLocalizationSubmit = async (localization) => {
    try {
      if (_.isEqual(localization, salon)) {
        setEditLocalization(false);
        return;
      }
      const wilaya = wilayasJSON.find((w) => w.code === localization.wilaya);
      const { salon: data } = await updateSalon(id, {
        ...localization,
        wilaya: { code: wilaya.code, name: wilaya.name }
      });
      setSalon(data);
      setEditLocalization(false);
      toastMessage('Changements sauvegardé avec succès !', 'success');
    } catch (error) {
      toastMessage(
        error.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };

  const [editOpenDates, setEditOpenDates] = React.useState(false);
  const openDatesFormRef = React.useRef(null);
  const handleSubmitOpenDates = async ({ openDates }) => {
    try {
      if (_.isEqual(openDates, salon.openDates)) {
        setEditOpenDates(false);
        return;
      }
      const { salon: data } = await updateSalon(id, {
        openDates
      });
      setSalon(data);
      setEditOpenDates(false);
      toastMessage('Changements sauvegardé avec succès !', 'success');
    } catch (error) {
      toastMessage(
        error.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };

  const [editSocialMedias, setEditSocialMedias] = React.useState(false);
  const socialMediasFormRef = React.useRef(null);
  const handleSocialMediasSubmit = async (values) => {
    try {
      if (_.isEqual(values, salon)) {
        setEditSocialMedias(false);
        return;
      }
      const { salon: data } = await updateSalon(id, values);
      setSalon(data);
      setEditSocialMedias(false);
      toastMessage('Changements sauvegardé avec succès !', 'success');
    } catch (error) {
      toastMessage(
        error.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };

    const [editGallerie, setEditGallerie] = React.useState(false);
    const gallerieFormRef = React.useRef(null);
    const handleGallerieSubmit = async (values) => {
      try {
        if (_.isEqual(values, salon)) {
          setEditGallerie(false);
          return;
        }
        const { salon: data } = await updateSalon(id, values);
        setSalon(data);
        setEditGallerie(false);
        toastMessage('Changements sauvegardé avec succès !', 'success');
      } catch (error) {
        toastMessage(
          error.response?.data?.message || DEFAULT_ERROR_MESSAGE,
          'error'
        );
      }
    };


  const handleTabChange = (e, value) => {
    setActiveTab(value);
  };

  const [togglingCertified, setTogglingCertified] = React.useState(false);
  const toggleCertified = async () => {
    try {
      setTogglingCertified(true);
      await updateSalon(id, { isCertified: !salon.isCertified });
      setSalon((s) => ({ ...s, isCertified: !salon.isCertified }));
      setTogglingCertified(false);
      toastMessage('Changements sauvegardé avec succès !', 'success');
    } catch (error) {
      setTogglingCertified(false);
      toastMessage(
        error.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };

  const [togglingStatus, setTogglingStatus] = React.useState(false);
  const toggleStatus = async () => {
    try {
      setTogglingStatus(true);
      await updateSalon(id, { isActive: !salon.isActive });
      setSalon((s) => ({ ...s, isActive: !salon.isActive }));
      setTogglingStatus(false);
      toastMessage('Changements sauvegardé avec succès !', 'success');
    } catch (error) {
      setTogglingStatus(false);
      toastMessage(
        error.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }
  return (
    <>
      <Helmet>
        <title>{salon?.name || 'Salon'} | Izya</title>
      </Helmet>
      <Box sx={{ width: '100%', p: 2 }}>
        <Box sx={{ mt: 3 }}>
          <Card
            sx={{
              p: 2,
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Typography
              sx={{ justifySelf: 'flex-start', marginLeft: 0 }}
              variant="h2"
            >
              {loading || !salon ? 'Chargement ...' : salon.name}
            </Typography>
            <Box sx={{ display: 'flex', gap: '10px' }}>
              {salon?.isCertified ? (
                <Button
                  variant="outlined"
                  color="success"
                  disabled={togglingCertified}
                  onClick={toggleCertified}
                >
                  <Check sx={{ mr: 1 }} /> Certifié
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="warning"
                  disabled={togglingCertified}
                  onClick={toggleCertified}
                >
                  <Close sx={{ mr: 1 }} /> Non Certifié
                </Button>
              )}
              {salon?.isActive ? (
                <Button
                  variant="outlined"
                  color="success"
                  disabled={togglingStatus}
                  onClick={toggleStatus}
                >
                  <Check sx={{ mr: 1 }} /> Actif
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="error"
                  disabled={togglingStatus}
                  onClick={toggleStatus}
                >
                  <Close sx={{ mr: 1 }} /> Inactif
                </Button>
              )}
            </Box>
          </Card>
        </Box>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            mt: 3,
            backgroundColor: '#fff'
          }}
        >
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Details" {...a11yProps(0)} />
            <Tab label="Spécialités" {...a11yProps(1)} />
            <Tab label="Consultants" {...a11yProps(2)} />
            <Tab label="Localisation" {...a11yProps(3)} />
            <Tab label="Horaires" {...a11yProps(4)} />
            <Tab label="Réseaux sociaux" {...a11yProps(2)} />
            <Tab label="Gallerie" {...a11yProps(6)} />
          </Tabs>
        </Box>
        <TabPanel value={activeTab} index={0}>
          <Card sx={{ py: 3, px: 2 }}>
            <SalonDetailsForm
              readOnly={!editDetails}
              data={salon}
              setData={setSalon}
              onSubmit={handleDetailsSubmit}
              formRef={detailsFormRef}
            />
            <Box sx={{ mt: 2 }}>
              {editDetails ? (
                <Button
                  variant="contained"
                  onClick={() => {
                    detailsFormRef.current?.handleSubmit();
                  }}
                >
                  Sauvegarder les changement
                </Button>
              ) : (
                <Button variant="outlined" onClick={() => setEditDetails(true)}>
                  Modifier les details
                </Button>
              )}
            </Box>
          </Card>
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <Card sx={{ py: 3, px: 2 }}>
            <SpecialtyProvider>
              <SalonsSpecialtiesForm
                createData={salon}
                setCreateData={setSalon}
              />
              <Button
                variant="contained"
                onClick={() => handleSpecialtiesSubmit()}
                sx={{ mt: 1 }}
              >
                Sauvegarder les changement
              </Button>
            </SpecialtyProvider>
          </Card>
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <Card sx={{ py: 3, px: 2 }}>
            <SalonsConsultantsForm
              createData={salon}
              setCreateData={setSalon}
            />
            <Button
              variant="contained"
              onClick={() => handleConsultantsSubmit()}
              sx={{ mt: 1 }}
            >
              Sauvegarder les changement
            </Button>
          </Card>
        </TabPanel>
        <TabPanel value={activeTab} index={3}>
          <Card sx={{ py: 3, px: 2 }}>
            <SalonsLocalizationForm
              onSubmit={handleLocalizationSubmit}
              data={salon}
              formRef={localizationFormRef}
              readOnly={!editLocalization}
            />
            <Box sx={{ mt: 2 }}>
              {editLocalization ? (
                <Button
                  variant="contained"
                  onClick={() => {
                    localizationFormRef.current?.handleSubmit();
                  }}
                >
                  Sauvegarder les changement
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => setEditLocalization(true)}
                >
                  Modifier la localization
                </Button>
              )}
            </Box>
          </Card>
        </TabPanel>
        <TabPanel value={activeTab} index={4}>
          <Card sx={{ py: 3, px: 2 }}>
            <SalonOpenDatesForm
              data={salon}
              formRef={openDatesFormRef}
              onSubmit={handleSubmitOpenDates}
              readOnly={!editOpenDates}
            />
            <Box sx={{ mt: 2 }}>
              {editOpenDates ? (
                <Button
                  variant="contained"
                  onClick={() => {
                    openDatesFormRef.current?.handleSubmit();
                  }}
                >
                  Sauvegarder les changement
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => setEditOpenDates(true)}
                >
                  Modifier les horaires
                </Button>
              )}
            </Box>
          </Card>
        </TabPanel>

        <TabPanel value={activeTab} index={5}>
          <Card sx={{ py: 3, px: 2 }}>
            <SalonsSocialMediasForm
              data={salon}
              readOnly={!editSocialMedias}
              formRef={socialMediasFormRef}
              onSubmit={handleSocialMediasSubmit}
            />
            <Box sx={{ mt: 2 }}>
              {editSocialMedias ? (
                <Button
                  variant="contained"
                  onClick={() => {
                    socialMediasFormRef.current?.handleSubmit();
                  }}
                >
                  Sauvegarder les changement
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => setEditSocialMedias(true)}
                >
                  Modifier
                </Button>
              )}
            </Box>
          </Card>
        </TabPanel>
        <TabPanel value={activeTab} index={6}>
          <Card sx={{ py: 3, px: 2 }}>
            <SalonMediaForm
              data={salon}
              readOnly={!editGallerie}
              formRef={gallerieFormRef}
              onSubmit={handleGallerieSubmit}
            />
            <Box sx={{ mt: 2 }}>
              {editGallerie ? (
                <Button
                  variant="contained"
                  onClick={() => {
                    gallerieFormRef.current?.handleSubmit();
                  }}
                >
                  Sauvegarder les changement
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => setEditGallerie(true)}
                >
                  Modifier
                </Button>
              )}
            </Box>
          </Card>
        </TabPanel>
      </Box>
    </>
  );
};

export default () => (
  <SalonsProvider>
    <Salon />
  </SalonsProvider>
);
