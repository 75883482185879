import React, { useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  DialogTitle,
  FormHelperText,
  FormControl,
  MenuItem,
  Select,
  InputLabel
} from '@material-ui/core';
import { useSubService } from 'src/contexts/SubServiceContext';
import { Formik } from 'formik';
import * as yup from 'yup';

const SubServicesModal = ({
  open,
  handleClose,
  handleSubmit,
  title,
  buttonTitle
}) => {
  const { subService, services } = useSubService();

 /* useEffect(() => {
    console.log({ services }, 'from sub services modal');
  }, [services]); */
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <Formik
        initialValues={{
          name: subService?.name || '',
          services: subService?.services?.map((s) => s?._id || s) || []
        }}
        validationSchema={yup.object().shape({
          name: yup.string().required('Vous devez définir le nom de service.'),
          services: yup
            .array()
            .min(1)
            .required('Vous devez choisir au moins un service.')
        })}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit: onSubmit,
          handleChange,
          setFieldValue,
          values,
          errors,
          touched
        }) => (
          <form onSubmit={onSubmit}>
            <DialogContent>
              <FormControl fullWidth error={!!errors.name}>
                <TextField
                  error={!!errors.name && !!touched.name}
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Nom de prestation"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={values.name}
                  onChange={handleChange('name')}
                />
                {!!errors && !!touched && !!errors.name && !!touched.name && (
                  <FormHelperText sx={{ marginLeft: 0.5 }}>
                    {errors.name}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl
                fullWidth
                error={!!errors.specialty && !!touched.specialty}
              >
                <InputLabel id="services-select">Services</InputLabel>
                <Select
                  labelId="services-select"
                  style={{ flex: 1 }}
                  value={values.services}
                  onChange={(e) => {
                    setFieldValue('services', e.target.value);
                  }}
                  label="services"
                  placeholder="services"
                  fullWidth
                  multiple
                >
                  {services.map((s) => (
                    <MenuItem value={s._id} key={s._id}>
                      {s.name}
                    </MenuItem>
                  ))}
                </Select>
                {!!errors &&
                  !!touched &&
                  !!errors.specialty &&
                  !!touched.specialty && (
                    <FormHelperText sx={{ marginLeft: 0.5 }}>
                      {errors.specialty}
                    </FormHelperText>
                  )}
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleClose();
                }}
              >
                Annuler
              </Button>
              <Button onClick={onSubmit}>{buttonTitle}</Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default SubServicesModal;
