import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import { Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import wilayasJSON from '../../../assets/wilayas.json';

const SalonsLocalizationForm = ({ onSubmit, formRef, data, readOnly }) => (
  <Formik
    initialValues={{
      wilaya: data.wilaya ? data.wilaya.code : '',
      localisation: {
        lat: data?.localisation?.lat || 0,
        lng: data?.localisation?.lng || 0
      },
      radius: data?.radius || 13000,
      address: data?.address
    }}
    enableReinitialize
    validationSchema={yup.object().shape({
      wilaya: yup.string().required('Champ obligatoire.'),
      localisation: yup.object({
        lat: yup.number(),
        lng: yup.number()
      }),
      radius: yup.number().required(),
      address: yup.string().required('Champ obligatoire.')
    })}
    innerRef={formRef}
    onSubmit={onSubmit}
  >
    {({ handleSubmit, values, setFieldValue, handleChange, errors }) => (
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid xs={12} item>
            <FormControl fullWidth error={!!errors.wilaya}>
              <InputLabel id="wilayas-select">Wilaya</InputLabel>
              <Select
                label="wilaya"
                fullWidth
                labelId="wilayas-select"
                onChange={(e) => {
                  setFieldValue('wilaya', e.target.value);
                }}
                value={values.wilaya}
                error={!!errors.wilaya}
                variant="outlined"
                disabled={readOnly}
              >
                {wilayasJSON.map((w) => (
                  <MenuItem value={w.code} key={w.code}>
                    {w.code} - {w.name}
                  </MenuItem>
                ))}
              </Select>
              {!!errors && !!errors.wilaya && (
                <FormHelperText sx={{ marginLeft: 0.5 }}>
                  {errors.wilaya}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid xs={12} item>
            <FormControl fullWidth error={!!errors.localisation?.lat}>
              <TextField
                label="Latitude"
                placeholder="Latitude"
                type="number"
                fullWidth
                value={values.localisation?.lat}
                error={!!errors.localisation?.lat}
                variant="outlined"
                onChange={(e) =>
                  setFieldValue('localisation.lat', e.target.value)
                }
                disabled={readOnly}
              />
              {!!errors && !!errors.localisation?.lat && (
                <FormHelperText sx={{ marginLeft: 0.5 }}>
                  {errors.localisation?.lat}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid xs={12} item>
            <FormControl fullWidth error={!!errors.localisation?.lng}>
              <TextField
                label="Longitude"
                placeholder="Longitude"
                type="number"
                fullWidth
                value={values.localisation?.lng}
                error={!!errors.localisation?.lng}
                variant="outlined"
                onChange={(e) =>
                  setFieldValue('localisation.lng', e.target.value)
                }
                disabled={readOnly}
              />
              {!!errors && !!errors.localisation?.lng && (
                <FormHelperText sx={{ marginLeft: 0.5 }}>
                  {errors.localisation?.lng}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid xs={12} item>
            <FormControl fullWidth error={!!errors.radius}>
              <TextField
                type="number"
                label="Radius"
                placeholder="Radius"
                fullWidth
                value={values.radius}
                error={!!errors.radius}
                variant="outlined"
                onChange={handleChange('radius')}
                disabled={readOnly}
              />

              {!!errors && !!errors.address && (
                <FormHelperText sx={{ marginLeft: 0.5 }}>
                  {errors.address}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid xs={12} item>
            <FormControl fullWidth error={!!errors.address}>
              <TextField
                label="Adresse"
                placeholder="Adresse"
                fullWidth
                value={values.address}
                error={!!errors.address}
                variant="outlined"
                onChange={handleChange('address')}
                disabled={readOnly}
              />

              {!!errors && !!errors.address && (
                <FormHelperText sx={{ marginLeft: 0.5 }}>
                  {errors.address}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </form>
    )}
  </Formik>
);

export default SalonsLocalizationForm;
