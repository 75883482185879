import React from 'react';
import { DEFAULT_ERROR_MESSAGE } from 'src/utils/constants';
import toastMessage from 'src/utils/toast';
import axios from '../utils/axios';

const OurPixContext = React.createContext({});

export const OurPixProvider = ({ children }) => {
  const [ourPixes, setOurPixes] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [fetching, setFetching] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [pageSize] = React.useState(10);
  const [search, setSearch] = React.useState('');
  const [ourPix, setOurPix] = React.useState({});
  const [toDelete, setToDelete] = React.useState(null);

  const create = async (data) => {
    try {
      const { data: created } = await axios.post('/v1/admin/our-pix', data);
      console.log(created);
      setOurPixes([...ourPixes, created]);
      return Promise.resolve(created);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  const update = async (data) => {
    try {

      const { data: updated } = await axios.put(`/v1/admin/our-pix`, {
        ...data,
        ...{ id: ourPix._id }
      });

      const index = ourPixes.findIndex((s) => s._id === ourPix._id);

      if (index !== -1) {
        const newAds = [...ourPixes];
        newAds[index] = { ...newAds[index], ...data };
        setOurPixes(newAds);
      }
      setOurPix(null);
      return Promise.resolve(updated);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const remove = async () => {
    try {
      await axios.delete(`/v1/admin/our-pix/${toDelete._id}`, {
        data: {}
      });
      setOurPixes(ourPixes.filter((s) => s._id !== toDelete._id));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getAll = async () => {
    try {
      setFetching(true);
      const { data } = await axios.get(`/v1/admin/our-pix`);
      setFetching(false);
      setOurPixes(data.result);

      setTotalCount(data.result.length);
    } catch (error) {
      setFetching(false);
      toastMessage(
        error?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };

  React.useEffect(() => {
    getAll();
  }, []);

  React.useEffect(() => {
    getAll(search);
  }, [search]);

  return (
    <OurPixContext.Provider
      value={{
        create,
        update,
        remove,
        getAll,
        ourPixes,
        ourPix,
        fetching,
        page,
        setPage,
        pageSize,
        totalCount,
        search,
        setSearch,
        setOurPix,
        toDelete,
        setToDelete
      }}
    >
      {children}
    </OurPixContext.Provider>
  );
};

export const useOurPix = () => React.useContext(OurPixContext);
