import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  DialogTitle,
  FormHelperText,
  FormControl,
  MenuItem,
  Select,
  InputLabel
} from '@material-ui/core';
import { useService } from 'src/contexts/ServiceContext';
import { Formik } from 'formik';
import * as yup from 'yup';

const ServicesModal = ({
  open,
  handleClose,
  handleSubmit,
  title,
  buttonTitle
}) => {
  const { service, specialties } = useService();

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <Formik
        initialValues={{
          name: service?.name || '',
          specialty: service?.specialty?._id || service?.specialty || ''
        }}
        validationSchema={yup.object().shape({
          name: yup.string().required('Vous devez définir le nom de service.'),
          specialty: yup.string().required('Vous devez choisir la specialité.')
        })}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit: onSubmit,
          handleChange,
          setFieldValue,
          values,
          errors,
          touched
        }) => (
          <form onSubmit={onSubmit}>
            <DialogContent>
              <FormControl fullWidth error={!!errors.name}>
                <TextField
                  error={!!errors.name && !!touched.name}
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Nom de service"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={values.name}
                  onChange={handleChange('name')}
                />
                {!!errors && !!touched && !!errors.name && !!touched.name && (
                  <FormHelperText sx={{ marginLeft: 0.5 }}>
                    {errors.name}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl
                fullWidth
                error={!!errors.specialty && !!touched.specialty}
              >
                <InputLabel id="specialties-select">Spécialités</InputLabel>
                <Select
                  labelId="specialties-select"
                  style={{ flex: 1 }}
                  value={values.specialty}
                  onChange={(e) => {
                    setFieldValue('specialty', e.target.value);
                  }}
                  label="spécialités"
                  placeholder="spécialité"
                  fullWidth
                >
                  {specialties.map((s) => (
                    <MenuItem value={s._id} key={s._id}>
                      {s.name}
                    </MenuItem>
                  ))}
                </Select>
                {!!errors &&
                  !!touched &&
                  !!errors.specialty &&
                  !!touched.specialty && (
                    <FormHelperText sx={{ marginLeft: 0.5 }}>
                      {errors.specialty}
                    </FormHelperText>
                  )}
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleClose();
                }}
              >
                Annuler
              </Button>
              <Button onClick={onSubmit}>{buttonTitle}</Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ServicesModal;
