import React from 'react';
import { DEFAULT_ERROR_MESSAGE } from 'src/utils/constants';
import toastMessage from 'src/utils/toast';
import axios from '../utils/axios';

const AppointmentsContext = React.createContext({});

export const AppointmentsProvider = ({ children }) => {
  const [appointements, setAppointements] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [fetching, setFetching] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [pageSize] = React.useState(10);

  const fetchAppointments = async (currentPage = 0) => {
    try {
      setFetching(true);
      const { data } = await axios.get(
        `v1/admin/appointement?page=${currentPage}&limit=${pageSize}`
      );
      setFetching(false);
      setAppointements(data.results);
      setTotalCount(data.totalResults);
    } catch (error) {
      setFetching(false);
      toastMessage(
        error?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };

  React.useEffect(() => {
    fetchAppointments();
  }, []);

  React.useEffect(() => {
    if (page !== 0) {
      fetchAppointments(page + 1);
    }
  }, [page]);

  return (
    <AppointmentsContext.Provider
      value={{
        appointements,
        fetching,
        fetchAppointments,
        page,
        setPage,
        pageSize,
        totalCount
      }}
    >
      {children}
    </AppointmentsContext.Provider>
  );
};

export const useAppointements = () => React.useContext(AppointmentsContext);
