import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export default function PrivateRoute({ component: Component, ...rest }) {
  const isAuth = !!localStorage.getItem('izya_auth');
  if (!Component) return null;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuth) {
          return <Component {...props} />;
        }
        return <Redirect to="/signin" />;
      }}
    />
  );
}
