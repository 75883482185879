import React from 'react';
import { Card, IconButton } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { Edit, DeleteOutline } from '@material-ui/icons';
import { useOurPix } from 'src/contexts/OurPixContext';
import Empty from 'src/assets/Empty';

const OurPixResults = ({ onEdit, onDelete, ...rest }) => {

  const {ourPixes, totalCount, pageSize, fetching, search } =
    useOurPix();

  const [list, setList] = React.useState([]);

  React.useEffect(() => {
     if (search.trim()) {
       setList(
         ourPixes.filter((s) =>
           s.salon.name.toLowerCase().startsWith(search.toLowerCase())
         )
       );
       return;
     }
    setList(ourPixes);
  }, [ourPixes]);

  const columns = [
    {
      field: 'salon',
      headerName: 'Nom du salon',
      flex: 1,
      valueFormatter: ({ value }) => value.name
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 135,
      renderCell: ({ row }) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            gap: 10
          }}
        >
          <IconButton onClick={() => onDelete(row)}>
            <DeleteOutline color="error" />
          </IconButton>
        </div>
      )
    }
  ];

  return list.length > 0 ? (
    <Card {...rest} style={{ width: '100%', height: '60vh' }}>
      {list === undefined ? (
        <Empty label="loading" />
      ) : (
        <DataGrid
          rows={list}
          columns={columns}
          pageSize={pageSize}
          loading={fetching}
          isRowSelectable={false}
          paginationMode="server"
          rowCount={totalCount}
          disableSelectionOnClick
          getRowId={(row) => row._id || row.id}
        />
      )}
    </Card>
  ) : (
    <></>
  );
};

export default OurPixResults;
