import { useEffect } from 'react';
import {
  Link as RouterLink,
  useLocation,
  useRouteMatch
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography
} from '@material-ui/core';
import { Users as UsersIcon } from 'react-feather';
import { Storefront, BusinessCenter, LocalMall } from '@material-ui/icons';
import BarChartIcon from '@mui/icons-material/BarChart';
import CampaignIcon from '@mui/icons-material/Campaign';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import NavItem from './NavItem';
import avatarPlaceholder from '../images/avatar-placeholder.png';

const user = {
  avatar: avatarPlaceholder,
  jobTitle: 'Administrateur',
  name: 'Izya default'
};

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const match = useRouteMatch();
  const items = [
    {
      href: `${match.path}/salons`,
      icon: Storefront,
      title: 'Salons'
    },
    {
      href: `${match.path}/our-pix`,
      icon: Storefront,
      title: 'Our pix'
    },
    {
      href: `${match.path}/appointements`,
      icon: BookmarkIcon,
      title: 'Rendez-vous'
    },
    {
      href: `${match.path}/kpi`,
      icon: BarChartIcon,
      title: 'Kpis'
    },
    {
      href: `${match.path}/specialties`,
      icon: BusinessCenter,
      title: 'Spécialités'
    },
    {
      href: `${match.path}/services`,
      icon: LocalMall,
      title: 'Services'
    },
    {
      href: `${match.path}/prestation`,
      icon: VolunteerActivismIcon,
      title: 'Prestation'
    },
    {
      href: `${match.path}/ads`,
      icon: CampaignIcon,
      title: 'Ads'
    }
    // {
    //   href: `${match.path}/users`,
    //   icon: UsersIcon,
    //   title: 'Clients'
    // }
  ];
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar
          component={RouterLink}
          src={user.avatar}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64
          }}
          to="/app/account"
        />
        <Typography color="textPrimary" variant="h5">
          {user.name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default DashboardSidebar;
