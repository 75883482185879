import React from 'react';
import { DEFAULT_ERROR_MESSAGE } from 'src/utils/constants';
import toastMessage from 'src/utils/toast';
import axios from '../utils/axios';

const ServiceContext = React.createContext({});

export const ServiceProvider = ({ children }) => {
  const [services, setServices] = React.useState([]);
  const [specialties, setSpecialties] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [totalCountSp, setTotalCountSp] = React.useState(0);
  const [fetching, setFetching] = React.useState(false);
  const [fetchingSp, setFetchingSp] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [pageSize] = React.useState(10);
  const [search, setSearch] = React.useState('');
  const [service, setService] = React.useState(null);
  const [specialty, setSpecialty] = React.useState('');
  const [toDelete, setToDelete] = React.useState(null);
  const [selectedSpecialty, setSelectedSpecialty] = React.useState('');
  const [defaultSpecialty, setDefaultSpecialty] = React.useState('');

  const fetchSpecialties = async () => {
    try {
      setFetchingSp(true);
      const { data } = await axios.get(`/v1/specialties`);
      setFetchingSp(false);
      setSpecialties(data);
      setTotalCountSp(data.length);
      setDefaultSpecialty(data[0]._id);
    } catch (error) {
      setFetchingSp(false);
      toastMessage(
        error?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };

  const fetchServices = async (idSpecialty, offset = 0) => {
    
    try {
      setFetching(true);
      const { data } = await axios.get(
        `/v1/admin/services/${idSpecialty}?limit=${pageSize}&page=${offset}`
      );
      setFetching(false);
      setServices(data.results);
      setTotalCount(data.totalResults);
    } catch (error) {
      setFetching(false);
      toastMessage(
        error?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };

  const addService = async (data) => {
    try {
      const { data: element } = await axios.post('/v1/admin/services', data);
      const createdService = element.service;
      setServices([...services, createdService]);
      return Promise.resolve(createdService);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const updateService = async (data) => {
    try {
      const { data: updated } = await axios.put(`/v1/admin/services`, {
        id: service._id,
        specialty: data.specialty,
        name: data.name
      });

      const index = services.findIndex((s) => s._id === service._id);
      if (index !== -1) {
        const newServices = [...services];
        newServices[index] = { ...newServices[index], ...data };
        setServices(newServices);
      }
      setService(null);
      return Promise.resolve(updated);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const deleteService = async () => {
    try {
      await axios.delete(`/v1/admin/services/`, {
        data: { id: toDelete._id }
      });
      setServices(services.filter((s) => s._id !== toDelete._id));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  React.useEffect(() => {
    fetchSpecialties();
    fetchServices(selectedSpecialty, page);
  }, []);

  React.useEffect(() => {
    if (page >= 0) {
      fetchServices(selectedSpecialty, page + 1);
    }
  }, [page]);

  return (
    <ServiceContext.Provider
      value={{
        services,
        specialties,
        fetching,
        fetchingSp,
        fetchServices,
        fetchSpecialties,
        page,
        setPage,
        pageSize,
        totalCount,
        search,
        setSearch,
        service,
        specialty,
        setServices,
        setService,
        setSpecialty,
        setSpecialties,
        addService,
        updateService,
        deleteService,
        toDelete,
        setToDelete,
        selectedSpecialty,
        setSelectedSpecialty
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
};

export const useService = () => React.useContext(ServiceContext);
