
import React from 'react';
import axios from '../utils/axios';

const AppointementContext = React.createContext({});

export const AppointementProvider = ({ children }) => {
  const [Appointements, setAppointements] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [idSalon, setIdSalon] = React.useState(null);
  const [pageSize] = React.useState(10);
  const [fetching, setFetching] = React.useState(false);

  const getAppointements = async (id) => {
    const { data } = await axios.get(
      `/v1/admin/appointement/by-a-salon/${id}`
    );
    setFetching(false);
    setTotalCount(data.results.length);
    setAppointements(data.results);
    return data;
  };


  return (
    <AppointementContext.Provider
      value={{
        Appointements,
        getAppointements,
        page,
        pageSize,
        fetching,
        totalCount,
        setIdSalon
      }}
    >
      {children}
    </AppointementContext.Provider>
  );
};

export const useAppointement = () => React.useContext(AppointementContext);
