import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Card, Container, Typography } from '@material-ui/core';
import { AppointmentsProvider, useAppointements } from 'src/contexts/AppointmentsContext';
import Empty from 'src/assets/Empty';
import { DataGrid } from '@mui/x-data-grid';

const List = () => {
  const {
    appointements,
    fetching,
    page,
    setPage,
    pageSize,
    totalCount
  } = useAppointements();

  const getName = (client) => {
    if (!client) {
      return '-';
    }

    if (client.fullName) {
      return client.fullName;
    }

    if (!client.firstName && !client.lastName) {
      return '-';
    }

    return `${client.firstName} ${client.lastName}`;
  }

  const columns = [
    {
      field: 'salon',
      headerName: 'Nom salon',
      valueFormatter: ({ value }) => value?.name,
      flex: 1
    },
    {
      field: 'salonSubService',
      headerName: 'prestations',
      width: 150,
      renderCell: (params) => (
        <ul className="flex">
          {params.value.map((role, index) => (
            <li>{role.subService.name}</li>
          ))}
        </ul>
      ),
      type: 'string',
      flex: 2
    },
    {
      field: 'client',
      headerName: 'Nom client',
      valueFormatter: ({ value }) => getName(value),
      flex: 1
    },
    {
      field: 'totalAmount',
      headerName: 'Prix totale',
      flex: 1
    },
    {
      field: 'date',
      headerName: 'Date debut',
      flex: 1
    },
    {
      field: 'endingDate',
      headerName: 'Date fin',
      flex: 1
    }
  ];

  return (
    <>
      <Helmet>
        <title>Rendez-vous | Izya</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Box>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h2" color="darkText">
                Rendez-vous
              </Typography>
            </div>
          </Box>
          <Box sx={{ pt: 3 }}>
            <Card style={{ width: '100%', height: '60vh' }}>
              {!fetching && appointements?.length === 0 ? (
                <Empty label="Aucun rendez vous !" />
              ) : (
                <DataGrid
                  rows={appointements}
                  columns={columns}
                  pageSize={pageSize}
                  page={page}
                  onPageChange={(p) => setPage(p)}
                  paginationMode="server"
                  rowCount={totalCount}
                  loading={fetching}
                  getRowId={(row) => row._id || row.id}
                />
              )}
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default () => (
  <AppointmentsProvider>
    <List />
  </AppointmentsProvider>
);
