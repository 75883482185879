import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import { DataGrid } from '@material-ui/data-grid';
import React from 'react';
import { useSpecialty } from 'src/contexts/SpecialtyContext';

const SalonsSpecialtiesForm = ({ createData, setCreateData }) => {
  const { specialties } = useSpecialty();
  const [selected, setSelected] = React.useState([]);
  const [list, setList] = React.useState([]);

  React.useEffect(() => {
    if (createData && createData.specialties) {
      setList(specialties);
      return;
    }
    setList(specialties);
  }, [specialties, createData]);

  const add = () => {
    const items = specialties.filter((s) => selected.includes(s._id));
    if (items) {
      const dataSpecialties = createData?.specialties || [];
      setCreateData((d) => ({
        ...d,
        specialties: [...dataSpecialties, ...items]
      }));
    }
    setSelected([]);
  };

  const remove = (id) => {
    setCreateData((d) => ({
      ...d,
      specialties: createData.specialties.filter((s) => s._id !== id)
    }));
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Spécialité',
      flex: 1
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: ({ row }) => (
        <IconButton onClick={() => remove(row._id)}>
          <Remove color="error" />
        </IconButton>
      )
    }
  ];

  return (
    <Grid container flexDirection="column" height={400} rowGap={2}>
      <Grid item container columnGap={1}>
        <FormControl style={{ flex: 1 }}>
          <InputLabel id="specialties-select">Spécialités</InputLabel>
          <Select
            labelId="specialties-select"
            style={{ flex: 1 }}
            value={selected}
            onChange={(e) => setSelected(e.target.value)}
            multiple
            label="spécialités"
            fullWidth
          >
            {list.map((s) => (
              <MenuItem value={s._id} key={s._id}>
                {s.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="outlined"
          style={{ height: 55 }}
          onClick={add}
          disabled={!selected.length}
        >
          <Add />
        </Button>
      </Grid>
      <DataGrid
        rows={createData?.specialties || []}
        getRowId={(row) => row._id || row.id}
        columns={columns}
        hideFooter
        style={{ height: '100%' }}
      />
    </Grid>
  );
};

export default SalonsSpecialtiesForm;
