import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import { OurPixProvider, useOurPix } from 'src/contexts/OurPixContext';
import DeleteModal from 'src/components/DeleteModal';
import toastMessage from 'src/utils/toast';
import { DEFAULT_ERROR_MESSAGE } from 'src/utils/constants';
import { useSalons } from 'src/contexts/SalonsContext';
import axios from 'axios';
import OurPixResult from '../../components/our-pix/OurPixResult';
import OurPixToolbar from '../../components/our-pix/OurPixToolbar';
// eslint-disable-next-line import/no-named-as-default-member
import OurPixModal from '../../components/our-pix/OurPixModal';

const OurPix = () => {
  const [addModal, setAddModal] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const { getAllSalons } = useSalons();
  const [lists, setLists] = React.useState([]);

  React.useEffect(async () => {
    const { data } = await axios.get(`/v1/admin/salons/all`);
    setLists(data.salons);
  }, [getAllSalons]);

  const {
    create,
    update,
    remove,
    getAll,
    fetching,
    page,
    setPage,
    pageSize,
    totalCount,
    search,
    setSearch,
    setOurPix,
    setToDelete
  } = useOurPix();

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const onEdit = (row) => {
    setOurPix(row);
    setEditModal(true);
  };

  const onDelete = (row) => {
    setToDelete(row);
    setDeleteModal(true);
  };

  const submitAdd = async (data, { resetForm }) => {
    try {
      setTimeout(async () => {
        await create(data);
        setAddModal(false);
        resetForm();
      }, 1000);
    } catch (error) {
      toastMessage(
        error?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };
  const submitUpdate = async (data, { resetForm }) => {
    try {
      await update(data);
      setEditModal(false);
      resetForm();
    } catch (error) {
      toastMessage(
        error?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };

  const submitDelete = async () => {
    try {
      await remove();
      setDeleteModal(false);
    } catch (error) {
      toastMessage(
        error?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };
  return (
    <>
      <Helmet>
        <title>Our pix | Izya</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <OurPixToolbar openModal={() => setAddModal(true)} />
          <Box sx={{ pt: 3 }}>
            <OurPixResult onEdit={onEdit} onDelete={onDelete} />
          </Box>
        </Container>
      </Box>
      <OurPixModal
        open={addModal}
        handleClose={() => setAddModal(false)}
        handleSubmit={submitAdd}
        title="Ajouter une pix"
        buttonTitle="Ajouter"
        salons={lists}
      />
      <OurPixModal
        open={editModal}
        handleClose={() => {
          setOurPix(null);
          setEditModal(false);
        }}
        handleSubmit={submitUpdate}
        title="Modifier"
        buttonTitle="Modifier"
        salons={lists}
      />
      <DeleteModal
        open={deleteModal}
        handleClose={closeDeleteModal}
        handleDelete={submitDelete}
        message={`Êtes vous sur de vouloir supprimer cette pix"${remove?.name}"`}
      />
    </>
  );
};

export default () => (
  <OurPixProvider>
    <OurPix />
  </OurPixProvider>
);
