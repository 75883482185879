import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import {
  SubServiceProvider,
  useSubService
} from 'src/contexts/SubServiceContext';
import DeleteModal from 'src/components/DeleteModal';
import toastMessage from 'src/utils/toast';
import { DEFAULT_ERROR_MESSAGE } from 'src/utils/constants';
import SubServicesResults from '../../components/subServices/SubServicesResults';
import SubServicesToolbar from '../../components/subServices/SubServicesToolbar';
// eslint-disable-next-line import/no-named-as-default-member
import SubServicesModal from '../../components/subServices/SubServicesModal';

const SubServices = () => {
  const [addModal, setAddModal] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);

  const {
    setSubService,
    addSubService,
    updateSubService,
    deleteSubService,
    setToDelete,
    toDelete
  } = useSubService();

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const onEdit = (row) => {
    setSubService(row);
    setEditModal(true);
  };

  const onDelete = (row) => {
    setToDelete(row);
    setDeleteModal(true);
  };

  const submitAdd = async (data, { resetForm }) => {
    try {
      await addSubService(data);
      setAddModal(false);
      resetForm();
    } catch (error) {
      toastMessage(
        error?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };
  const submitUpdate = async (data, { resetForm }) => {
    try {
      await updateSubService(data);
      setEditModal(false);
      resetForm();
    } catch (error) {
      toastMessage(
        error?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };

  const submitDelete = async () => {
    try {
      await deleteSubService();
      setDeleteModal(false);
    } catch (error) {
      toastMessage(
        error?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };
  return (
    <>
      <Helmet>
        <title>Prestations | Izya</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <SubServicesToolbar openModal={() => setAddModal(true)} />
          <Box sx={{ pt: 3 }}>
            <SubServicesResults onEdit={onEdit} onDelete={onDelete} />
          </Box>
        </Container>
      </Box>
      <SubServicesModal
        open={addModal}
        handleClose={() => setAddModal(false)}
        handleSubmit={submitAdd}
        title="Ajouter une prestation"
        buttonTitle="Ajouter"
      />
      <SubServicesModal
        open={editModal}
        handleClose={() => {
          setSubService(null);
          setEditModal(false);
        }}
        handleSubmit={submitUpdate}
        title="Modifier"
        buttonTitle="Sauvegarder"
      />
      <DeleteModal
        open={deleteModal}
        handleClose={closeDeleteModal}
        handleDelete={submitDelete}
        message={`Êtes vous sur de vouloir supprimer la prestation "${toDelete?.name}"`}
      />
    </>
  );
};

export default () => (
  <SubServiceProvider>
    <SubServices />
  </SubServiceProvider>
);
