import React from 'react';
import { DEFAULT_ERROR_MESSAGE } from 'src/utils/constants';
import toastMessage from 'src/utils/toast';
import axios from '../utils/axios';

const AdsContext = React.createContext({});

export const AdsProvider = ({ children }) => {

  const [publications, setPublications] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [fetching, setFetching] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [pageSize] = React.useState(10);
  const [search, setSearch] = React.useState('');
  const [publication, setPublication] = React.useState({});
  const [toDelete, setToDelete] = React.useState(null);

  const create = async (data) => {
    try {
  
      const formData = new FormData();
      formData.append('nameEntreprise', data.nameEntreprise);
      formData.append('description', data.description);
      formData.append('link', data.link);
      formData.append('startingDate', data.startingDate);
      formData.append('endingDate', data.endingDate);
      formData.append('status', data.status);
      formData.append('image', data.images);

      const { data: created } = await axios.post('/v1/admin/ads', formData);
    
      setPublications([...publications, created]);
      return Promise.resolve(created);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  const createImage=async(data)=>{
    try {
      const { data: created } = await axios.post('/v1/image/single-upload',
        data
      );
      return Promise.resolve(created);
    } catch (error) {
      return Promise.reject(error);
    }
  }
  const update = async (data) => {
    try {
      const formData = new FormData();
      formData.append('nameEntreprise', data.nameEntreprise);
      formData.append('description', data.description);
      formData.append('link', data.link);
      formData.append('startingDate', data.startingDate);
      formData.append('endingDate', data.endingDate);
      formData.append('status', data.status);
      formData.append('image', data.images);

      const { data: updated } = await axios.put(`/v1/admin/ads`, {
        ...formData,
        ...{ id: publication._id }
      });

      const index = publications.findIndex((s) => s._id === publication._id);
      
      if (index !== -1) {
        const newAds = [...publications];
        newAds[index] = { ...newAds[index], ...data };
        setPublications(newAds);
      }
      setPublication(null);
      return Promise.resolve(updated);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const remove = async () => {
    try {
      await axios.delete(`/v1/admin/ads/${toDelete._id}`, {
        data: {}
      });
      setPublications(publications.filter((s) => s._id !== toDelete._id));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getAll = async () => {
    try {
      setFetching(true);
      const { data } = await axios.get(`/v1/admin/ads/all/`);
      setFetching(false);
      setPublications(data.result);

      setTotalCount(data.length);
    } catch (error) {
      setFetching(false);
      toastMessage(
        error?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };

  React.useEffect(() => {
    getAll();
  }, []);

  React.useEffect(() => {
    getAll(search);
  }, [search]);

  return (
    <AdsContext.Provider
      value={{
        create,
        createImage,
        update,
        remove,
        getAll,
        publications,
        fetching,
        page,
        setPage,
        pageSize,
        totalCount,
        search,
        setSearch,
        publication,
        setPublication,
        toDelete,
        setToDelete
      }}
    >
      {children}
    </AdsContext.Provider>
  );
};

export const useAds = () => React.useContext(AdsContext);
