import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  DialogTitle,
  FormHelperText,
  FormControl
} from '@material-ui/core';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import { useAds } from 'src/contexts/AdsContext';
import { Formik } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as yup from 'yup';




const AdsModal = ({
  open,
  handleClose,
  handleSubmit,
  title,
  buttonTitle
}) => {
  const { publication, createImage } = useAds();
  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();


  const handleStartDate = (newValue, setFieldValue) => {
    const startingDate = new Date(newValue).toISOString();
    setDateStart(startingDate);
    return startingDate;
  };
  const handleEndDate = (newValue, setFieldValue) => {
    const endingDate = new Date(newValue).toISOString();
    setDateEnd(endingDate);
    return endingDate;
  };

  const onChangeImg = async (e) => {
    const importedFile = e.target.files[0];
    return importedFile;
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{title}</DialogTitle>

      <Formik
        enableReintialize={{ enableReinitialize: true }}
        initialValues={{
          nameEntreprise: publication?.nameEntreprise || '',
          description: publication?.description || '',
          link: publication?.link || '',
          startingDate: publication?.startingDate || new Date().toISOString(),
          endingDate: publication?.endingDate || new Date().toISOString(),
          status: publication?.status || false,
          images: publication?.images || []
        }}
        validationSchema={yup.object().shape({
          // nameEntreprise: yup.string().required('Nom entrerprise obligatoire.')
          description: yup.string().required('Description obligatoire.')
          //  startingDate: yup.date().required('Champ obligatoire.'),
          //  endingDate: yup.date().required('Champ obligatoire.')
          // status: yup.string().required('Champ obligatoire.'),
        })}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit: onSubmit,
          handleChange,
          errors,
          values,
          setFieldValue
        }) => (
          <form onSubmit={onSubmit}>
            <DialogContent>
              <FormControl fullWidth error={!!errors.name}>
                <TextField
                  error={!!errors.name}
                  margin="dense"
                  id="nameEntreprise"
                  label="Nom Entreprise"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={values.nameEntreprise}
                  onChange={handleChange('nameEntreprise')}
                />
                <TextField
                  error={!!errors.name}
                  margin="dense"
                  id="description"
                  name="description"
                  label="Description"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={values.description}
                  onChange={handleChange('description')}
                />

                <br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <DesktopDatePicker
                      label="Date debut"
                      inputFormat="MM/DD/YYYY"
                      value={
                        values.startingDate ? values.startingDate : dateStart
                      }
                      onChange={(e) => {
                        const dateEnds = handleStartDate(e);
                        setFieldValue('startingDate', dateEnds);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
                <br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={6}>
                    <DesktopDatePicker
                      label="Date fin"
                      inputFormat="MM/DD/YYYY"
                      value={values.endingDate ? values.endingDate : dateEnd}
                      onChange={(e) => {
                        const dateEnds = handleEndDate(e);
                        setFieldValue('endingDate', dateEnds);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
                <TextField
                  error={!!errors.name}
                  margin="dense"
                  id="link"
                  name="link"
                  label="Link"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={values.link}
                  onChange={handleChange('link')}
                />
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="status"
                      id="status"
                      value={values.status}
                      onChange={handleChange('status')}
                    />
                  }
                  label="Active"
                />

                <input
                  id="images"
                  name="images"
                  type="file"
                  onChange={async (e) => {
                    handleChange(e);
                    const images = await onChangeImg(e);
                    setFieldValue('images', images);
                  }}
                />

                {!!errors && !!errors.name && (
                  <FormHelperText sx={{ marginLeft: 0.5 }}>
                    {errors.name}
                  </FormHelperText>
                )}
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleClose();
                }}
              >
                Annuler
              </Button>
              <Button onClick={onSubmit}>{buttonTitle}</Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AdsModal;
