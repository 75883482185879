import {
  FormControl,
  Grid,
  Box,
  Typography
} from '@material-ui/core';
import { Switch, TextField } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TimePicker from '@mui/lab/TimePicker';

import React from 'react';
import dayjs, { daysOfWeek } from 'src/utils/dayjs';
import { Formik } from 'formik';

const SalonMediaForm = ({ onSubmit, formRef, data, readOnly }) => {
  console.log(data.gallary);

  return (
    <Formik
      initialValues={{
        gallary: data.gallary.length > 0 ? data.gallary : []
      }}
      onSubmit={onSubmit}
      innerRef={formRef}
    >
      {({ values, setFieldValue }) => (
        <Grid container spacing={2} rowGap={2}>
          {values.gallary.map((item) => (
            <Grid item xs={4}>
              <Box className="col-sm-12">
                <img
                  width={300}
                  height={300}
                  src={item.image}
                  className="img-fluid"
                  alt="test"
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Formik>
  );
};

export default SalonMediaForm;
