import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  DialogContentText
} from '@material-ui/core';

const DeleteModal = ({ open, handleClose, handleDelete, message }) => (
  <Dialog open={open} onClose={handleClose} fullWidth>
    <DialogTitle>Suppression</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {message || 'Êtes vous sur de vouloir supprimer cette sélection ?'}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={() => {
          handleClose();
        }}
        color="error"
      >
        Annuler
      </Button>
      <Button onClick={handleDelete} color="error">
        Supprimer
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteModal;
