import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import { ServiceProvider, useService } from 'src/contexts/ServiceContext';
import DeleteModal from 'src/components/DeleteModal';
import toastMessage from 'src/utils/toast';
import { DEFAULT_ERROR_MESSAGE } from 'src/utils/constants';
import ServicesResults from '../../components/services/ServicesResults';
import ServicesToolbar from '../../components/services/ServicesToolbar';
// eslint-disable-next-line import/no-named-as-default-member
import ServicesModal from '../../components/services/ServicesModal';

const Services = () => {
  const [addModal, setAddModal] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);

  const {
    setService,
    addService,
    updateService,
    deleteService,
    setToDelete,
    toDelete
  } = useService();

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const onEdit = (row) => {
    setService(row);
    setEditModal(true);
  };

  const onDelete = (row) => {
    setToDelete(row);
    setDeleteModal(true);
  };

  const submitAdd = async (data, { resetForm }) => {
    try {
      await addService(data);
      setAddModal(false);
      resetForm();
    } catch (error) {
      toastMessage(
        error?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };
  const submitUpdate = async (data, { resetForm }) => {
    try {
      await updateService(data);
      setEditModal(false);
      resetForm();
    } catch (error) {
      toastMessage(
        error?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };

  const submitDelete = async () => {
    try {
      await deleteService();
      setDeleteModal(false);
    } catch (error) {
      toastMessage(
        error?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };
  return (
    <>
      <Helmet>
        <title>Services | Izya</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <ServicesToolbar openModal={() => setAddModal(true)} />
          <Box sx={{ pt: 3 }}>
            <ServicesResults onEdit={onEdit} onDelete={onDelete} />
          </Box>
        </Container>
      </Box>
      <ServicesModal
        open={addModal}
        handleClose={() => setAddModal(false)}
        handleSubmit={submitAdd}
        title="Ajouter un service"
        buttonTitle="Ajouter"
      />
      <ServicesModal
        open={editModal}
        handleClose={() => {
          setService(null);
          setEditModal(false);
        }}
        handleSubmit={submitUpdate}
        title="Modifier"
        buttonTitle="Sauvegarder"
      />
      <DeleteModal
        open={deleteModal}
        handleClose={closeDeleteModal}
        handleDelete={submitDelete}
        message={`Êtes vous sur de vouloir supprimer le service "${toDelete?.name}"`}
      />
    </>
  );
};

export default () => (
  <ServiceProvider>
    <Services />
  </ServiceProvider>
);
