import React from 'react';
import { DEFAULT_ERROR_MESSAGE } from 'src/utils/constants';
import toastMessage from 'src/utils/toast';
import axios from '../utils/axios';

const SubServiceContext = React.createContext({});

export const SubServiceProvider = ({ children }) => {
  const [subServices, setSubServices] = React.useState([]);
  const [services, setServices] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [totalCountSp, setTotalCountSp] = React.useState(0);
  const [fetching, setFetching] = React.useState(false);
  const [fetchingSp, setFetchingSp] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [pageSize] = React.useState(10);
  const [search, setSearch] = React.useState('');
  const [subService, setSubService] = React.useState(null);
  const [specialty, setSpecialty] = React.useState('');
  const [toDelete, setToDelete] = React.useState(null);
  const [selectedServices, setSelectedServices] = React.useState([]);

  const fetchServices = async () => {
    try {
      setFetchingSp(true);
      const { data } = await axios.get(`/v1/services`);
      setFetchingSp(false);
      setServices(data);
      setTotalCountSp(data.length);
    } catch (error) {
      setFetchingSp(false);
      toastMessage(
        error?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };

  const fetchSubServices = async (servicesIds, offset = 0) => {
    try {
      setFetching(true);
      const { data } = await axios.get(`/v1/admin/subServices/`, {
        params: {
          services: servicesIds,
          limit: pageSize,
          page: offset
        }
      });
      setFetching(false);
      setSubServices(data.results);
      setTotalCount(data.totalResults);
    } catch (error) {
      setFetching(false);
      toastMessage(
        error?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };

  const addSubService = async (data) => {
    try {
      const service=data.services[0]
      const {name} = data;
      const dataSend={service,name}
      const { data: element } = await axios.post(
        '/v1/admin/subServices',
        dataSend
      );
      const createdSubService = element.subService;
      setSubServices([...subServices, createdSubService]);
      return Promise.resolve(createdSubService);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const updateSubService = async (data) => {
    try {
      const { data: updated } = await axios.put(`/v1/admin/subServices`, {
        id: subService._id,
        service: data.services,
        name: data.name
      });
      const index = subServices.findIndex((s) => s._id === subService._id);
      if (index !== -1) {
        const newSubServices = [...subServices];
        newSubServices[index] = { ...newSubServices[index], ...data };
        setSubServices(newSubServices);
      }
      setSubService(null);
      return Promise.resolve(updated);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const deleteSubService = async () => {
    try {
      await axios.delete(`/v1/admin/subServices/`, {
        data: { id: toDelete._id }
      });
      setSubServices(subServices.filter((s) => s._id !== toDelete._id));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  React.useEffect(() => {
    fetchServices();
    fetchSubServices(selectedServices, page);
  }, []);

  React.useEffect(() => {
    if (page >= 0) {
      fetchSubServices(selectedServices, page + 1);
    }
  }, [page]);

  return (
    <SubServiceContext.Provider
      value={{
        subServices,
        services,
        fetching,
        fetchingSp,
        fetchServices,
        fetchSubServices,
        page,
        setPage,
        pageSize,
        totalCount,
        search,
        setSearch,
        subService,
        specialty,
        setServices,
        setSubService,
        setSpecialty,
        addSubService,
        updateSubService,
        deleteSubService,
        toDelete,
        setToDelete,
        selectedServices,
        setSelectedServices
      }}
    >
      {children}
    </SubServiceContext.Provider>
  );
};

export const useSubService = () => React.useContext(SubServiceContext);
