import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import Appointements from './Appointements';
import List from './List';

const index = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={match.path} component={List} />
      <Route path={`${match.path}/:id`} component={Appointements} />
      <Redirect to={match.path} />
    </Switch>
  );
};

export default index;
