import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography,
  Select,
  Grid,
  MenuItem,
  FormControl,
  InputLabel
} from '@material-ui/core';

import { Search as SearchIcon } from 'react-feather';
import { useSubService } from 'src/contexts/SubServiceContext';

const SubServicesToolbar = ({ openModal }) => {
  const {
    search,
    setSearch,
    services,
    fetchSubServices,
    setSelectedServices,
    selectedServices
  } = useSubService();

  return (
    <Box>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h2" color="darkText">
          Prestation
        </Typography>
        <Button color="primary" variant="contained" onClick={openModal}>
          Ajouter une prestation
        </Button>
      </div>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid
              container
              display="flex"
              spacing={3}
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={6}>
                <FormControl style={{ flex: 1, width: '100%' }}>
                  <InputLabel id="services-select">Services</InputLabel>
                  <Select
                    labelId="services-select"
                    style={{ flex: 1 }}
                    value={selectedServices}
                    onChange={(e) => {
                      setSelectedServices(e.target.value);
                      fetchSubServices(e.target.value);
                    }}
                    label="services"
                    placeholder="services"
                    fullWidth
                    multiple
                  >
                    {services.map((s) => (
                      <MenuItem value={s._id} key={s._id}>
                        {s.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <TextField
                    fullWidth
                    style={{ paddingTop: 8 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Recherche prestation"
                    variant="outlined"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default SubServicesToolbar;
