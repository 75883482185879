import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  Stepper,
  Step,
  StepLabel,
  Box,
  Typography
} from '@material-ui/core';
import { useSalons } from 'src/contexts/SalonsContext';
import { SpecialtyProvider } from 'src/contexts/SpecialtyContext';
import _ from 'lodash';
import dayjs from 'dayjs';
import SalonDetailsForm from './SalonForms/SalonDetailsForm';
import SalonsSpecialtiesForm from './SalonForms/SalonsSpecialtiesForm';
import SalonsLocalizationForm from './SalonForms/SalonsLocalizationForm';
import SalonsSocialMediasForm from './SalonForms/SalonsSocialMediasForm';
import SalonMediaForm from './SalonForms/SalonMediaForm';
import toastMessage from '../../utils/toast';
import { DEFAULT_ERROR_MESSAGE } from '../../utils/constants';
import SalonsConsultantsForm from './SalonForms/SalonsConsultantsForm';
import SalonOpenDatesForm from './SalonForms/SalonOpenDatesForm';
import wilayasJSON from '../../assets/wilayas.json';

const SalonsModal = ({ open, handleClose }) => {
  const steps = [
    'Details',
    'Galerie',
    'Spécialités',
    'Consultants',
    'Localisation',
    'Horaires',
    'Réseaux sociaux'
  ];
  const detailsFormRef = React.useRef(null);
  const mediaFormRef = React.useRef(null);
  const localisationFormRef = React.useRef(null);
  const socialMediasFormRef = React.useRef(null);
  const openDatesFormRef = React.useRef(null);

  const [createData, setCreateData] = React.useState({});

  const { createStep, setCreateStep, createSalon } = useSalons();

  const nextStep = () => setCreateStep((step) => step + 1);
  const prevStep = () => setCreateStep((step) => step - 1);

  const submitDetails = (details) => {
    setCreateData((d) => ({ ...d, ...details }));
    nextStep();
  };

  const submitMedia = (medias) => {
    setCreateData((d) => ({ ...d, ...medias }));
    nextStep();
  };

  const submitLocalisation = (localisation) => {
    const wilaya = wilayasJSON.find((w) => w.code === localisation.wilaya);

    setCreateData((d) => ({
      ...d,
      ...localisation,
      wilaya: { code: wilaya.code, name: wilaya.name }
    }));
    nextStep();
  };

  const submitOpenDates = (data) => {
    setCreateData((d) => ({
      ...d,
      openDates: data.openDates
    }));
    nextStep();
  };

  const submitSocialMedias = (socialMedias) => {
    const data = {
      ...createData,
      ...socialMedias,
      consultants: createData.consultants.map((c) => _.omit(c, ['id'])),
      specialties: createData.specialties.map((s) => s._id)
    };

    createSalon(data)
      .then(() => handleClose())
      .catch((err) =>
        toastMessage(
          err.response?.data?.message || DEFAULT_ERROR_MESSAGE,
          'error'
        )
      );
  };

  const renderForms = () => {
    switch (createStep) {
      case 0:
        return (
          <SalonDetailsForm
            formRef={detailsFormRef}
            onSubmit={submitDetails}
            data={createData}
            setData={setCreateData}
          />
        );
      case 1:
        return (
          <SpecialtyProvider>
            <SalonsSpecialtiesForm
              setCreateData={setCreateData}
              createData={createData}
              setData={setCreateData}
            />
          </SpecialtyProvider>
        );
      case 2:
        return (
          <SalonsConsultantsForm
            setCreateData={setCreateData}
            createData={createData}
            setData={setCreateData}
          />
        );
      case 3:
        return (
          <SalonsLocalizationForm
            formRef={localisationFormRef}
            onSubmit={submitLocalisation}
            data={createData}
            setData={setCreateData}
          />
        );
      case 4:
        return (
          <SalonOpenDatesForm
            formRef={openDatesFormRef}
            onSubmit={submitOpenDates}
            data={createData}
            setData={setCreateData}
          />
        );
      case 5:
        return (
          <SalonsSocialMediasForm
            formRef={socialMediasFormRef}
            onSubmit={submitSocialMedias}
            data={createData}
            setData={setCreateData}
          />
        );
      case 6:
        return (
          <SalonMediaForm
            formRef={mediaFormRef}
            onSubmit={submitMedia}
            data={createData}
            setData={setCreateData}
          />
        );
      default:
        return null;
    }
  };

  const renderActions = () => {
    switch (createStep) {
      case 0:
        return (
          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
              }}
            >
              Annuler
            </Button>
            <Button
              onClick={() => {
                detailsFormRef.current?.handleSubmit();
              }}
            >
              Suivant
            </Button>
          </DialogActions>
        );
      case 1:
        return (
          <DialogActions>
            <Button
              onClick={() => {
                prevStep();
              }}
            >
              Précédent
            </Button>
            <Button
              onClick={nextStep}
              disabled={
                !createData.specialties || !createData.specialties?.length
              }
            >
              Suivant
            </Button>
          </DialogActions>
        );
      case 2:
        return (
          <DialogActions>
            <Button
              onClick={() => {
                prevStep();
              }}
            >
              Précédent
            </Button>
            <Button
              onClick={nextStep}
              disabled={
                !createData.consultants || !createData.consultants?.length
              }
            >
              Suivant
            </Button>
          </DialogActions>
        );
      case 3:
        return (
          <DialogActions>
            <Button
              onClick={() => {
                prevStep();
              }}
            >
              Précédent
            </Button>
            <Button
              onClick={() => {
                localisationFormRef.current?.handleSubmit();
              }}
            >
              Suivant
            </Button>
          </DialogActions>
        );

      case 4:
        return (
          <DialogActions>
            <Button
              onClick={() => {
                prevStep();
              }}
            >
              Précédent
            </Button>
            <Button
              onClick={() => {
                openDatesFormRef.current?.handleSubmit();
              }}
            >
              Suivant
            </Button>
          </DialogActions>
        );

      case 5:
        return (
          <DialogActions>
            <Button
              onClick={() => {
                prevStep();
              }}
            >
              Précédent
            </Button>
            <Button
              onClick={() => {
                mediaFormRef.current?.handleSubmit();
              }}
            >
              Terminer
            </Button>
          </DialogActions>
        );
      case 6:
        return (
          <DialogActions>
            <Button
              onClick={() => {
                prevStep();
              }}
            >
              Précédent
            </Button>
            <Button
              onClick={() => {
                socialMediasFormRef.current?.handleSubmit();
              }}
            >
              Terminer
            </Button>
          </DialogActions>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Ajouter un salon</DialogTitle>

      <DialogContent>
        <Stepper activeStep={createStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ width: '100%', marginTop: 4 }}>{renderForms()}</Box>
      </DialogContent>
      {renderActions()}
    </Dialog>
  );
};

export default SalonsModal;
