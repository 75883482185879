import React from 'react';
import { Card, Chip, IconButton } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { useSalons } from 'src/contexts/SalonsContext';
import {
  CheckCircle,
  AccessAlarm,
  Cancel,
  Edit,
  DeleteOutline,
  Check,
} from '@material-ui/icons';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Empty from 'src/assets/Empty';

const SalonsResults = ({ onDelete, onEdit, onView }) => {
  const { salons, page, setPage, pageSize, totalCount, fetching } = useSalons();
  const [list, setList] = React.useState([]);

  React.useEffect(() => {
    setList(salons);
  }, [salons]);
  const columns = [
    {
      field: 'name',
      headerName: 'Salon',
      flex: 1
    },
    {
      field: 'email',
      headerName: 'Email ',
      flex: 1
    },
    {
      field: 'isCertified',
      headerName: 'Certifié',
      flex: 1,
      renderCell: ({ value }) =>
        value ? (
          <Chip
            label="Oui"
            color="success"
            variant="outlined"
            icon={<CheckCircle color="success" />}
          />
        ) : (
          <Chip
            label="En attente"
            color="warning"
            variant="outlined"
            icon={<AccessAlarm color="warning" />}
          />
        )
    },
    {
      field: 'numberOfAppointement',
      headerName: 'Number of Appointement',
      flex: 1
    },
    {
      field: 'isActive',
      headerName: 'Status',
      flex: 1,
      renderCell: ({ value }) =>
        value ? (
          <Chip
            icon={<CheckCircle color="success" />}
            label="Actif"
            color="success"
            variant="outlined"
          />
        ) : (
          <Chip
            icon={<Cancel color="error" />}
            label="Inactif"
            color="error"
            variant="outlined"
          />
        )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: ({ row }) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            gap: 12
          }}
        >
          <IconButton onClick={() => onView(row._id)}>
            <VisibilityIcon color="success" />
          </IconButton>
          <IconButton onClick={() => onEdit(row._id)}>
            <Edit color="primary" />
          </IconButton>
          <IconButton onClick={() => onDelete(row)}>
            <DeleteOutline color="error" />
          </IconButton>
        </div>
      )
    }
  ];
  return (
    <Card style={{ width: '100%', height: '60vh' }}>
      {list.length === 0 ? (
        <Empty label="Aucun salon !" />
      ) : (
        <DataGrid
          rows={list}
          columns={columns}
          pageSize={pageSize}
          page={page}
          onPageChange={(p) => setPage(p)}
          paginationMode="server"
          rowCount={totalCount}
          loading={fetching}
          getRowId={(row) => row._id || row.id}
        />
      )}
    </Card>
  );
};

export default SalonsResults;
