import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AppointementProvider } from 'src/contexts/AppointementContext';
import AppointementsResults from '../../components/appointements/AppointementsResults';

const Appointements = () => {
  const history = useHistory();
  const match = useRouteMatch();
  
  return (
    <>
      <Helmet>
        <title>Appointements | Izya</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            <AppointementsResults />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default () => (
  <AppointementProvider>
    <Appointements />
  </AppointementProvider>
);
