import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { useSpecialty } from 'src/contexts/SpecialtyContext';

const SpecialtiesToolbar = ({ openModal }) => {
  const { search, setSearch } = useSpecialty();

  return (
    <Box>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h2" color="darkText">
          Spécialité
        </Typography>
        <Button color="primary" variant="contained" onClick={openModal}>
          Ajouter une spécialité
        </Button>
      </div>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Recherche spécialité"
                variant="outlined"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default SpecialtiesToolbar;
