import React from 'react';
import axios from '../utils/axios';

const AuthContext = React.createContext({ user: null });

export const AuthProvider = ({ children }) => {
  const [user, setUser] = React.useState(null);

  const initAuth = () => {
    const storageAuth = localStorage.getItem('izya_auth');
    if (storageAuth) {
      setUser({ username: 'admin' });
    }
  };

  const signIn = async (email, password) => {
    try {
      const { data } = await axios.post('/v1/admin/auth/signin', {
        email,
        password
      });
      setUser({ username: 'admin' });
      localStorage.setItem('izya_auth', JSON.stringify(data));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const signOut = () => {
    localStorage.clear();
    window.location.replace('/');
  };

  React.useEffect(() => {
    initAuth();
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, setUser, signIn, isAuth: !!user, initAuth, signOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
