import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField
} from '@material-ui/core';
import { Add, Clear } from '@material-ui/icons';
import { Formik } from 'formik';
import * as yup from 'yup';
import React from 'react';
import { PHONE_REGEX } from 'src/utils/constants';

const SalonDetailsForm = ({ onSubmit, formRef, data, readOnly = false }) => (
  <Formik
    initialValues={{
      name: data?.name || '',
      firstName: data?.firstName || '',
      lastName: data?.lastName || '',
      email: data?.email || '',
      phones: data?.phones || ['']
    }}
    validationSchema={yup.object().shape({
      name: yup.string().required('Champ obligatoire.'),
      firstName: yup.string().required('Champ obligatoire.'),
      lastName: yup.string().required('Champ obligatoire.'),
      email: yup
        .string()
        .email("L'adresse e-mail doit être valide.")
        .required('Champ obligatoire.'),
      phones: yup
        .array()
        .of(
          yup
            .string()
            .matches(PHONE_REGEX, 'Numéro de téléphone doit être valide.')
            .required('Champ obligatoire.')
        )
        .min(1, 'Au moins un numéro de téléphone est obligatoire.')
    })}
    enableReinitialize
    innerRef={formRef}
    onSubmit={onSubmit}
  >
    {({ handleSubmit, values, setFieldValue, handleChange, errors }) => (
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid xs={12} item>
            <FormControl fullWidth error={!!errors.name}>
              <TextField
                label="Nom du salon *"
                placeholder="Nom du salon *"
                fullWidth
                onChange={handleChange('name')}
                value={values.name}
                error={!!errors.name}
                variant="outlined"
                disabled={readOnly}
              />
              {!!errors && !!errors.name && (
                <FormHelperText sx={{ marginLeft: 0.5 }}>
                  {errors.name}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid xs={12} item>
            <FormControl fullWidth error={!!errors.email}>
              <TextField
                label="Adresse email *"
                placeholder="Adresse email *"
                fullWidth
                onChange={handleChange('email')}
                value={values.email}
                error={!!errors.email}
                variant="outlined"
                disabled={readOnly}
              />
              {!!errors && !!errors.email && (
                <FormHelperText sx={{ marginLeft: 0.5 }}>
                  {errors.email}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid xs={12} item>
            <FormControl fullWidth error={!!errors.lastName}>
              <TextField
                label="Prénom du propriétaire *"
                placeholder="Prénom du propriétaire *"
                fullWidth
                onChange={handleChange('firstName')}
                value={values.firstName}
                error={!!errors.firstName}
                variant="outlined"
                disabled={readOnly}
              />
              {!!errors && !!errors.lastName && (
                <FormHelperText sx={{ marginLeft: 0.5 }}>
                  {errors.lastName}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid xs={12} item>
            <FormControl fullWidth error={!!errors.firstName}>
              <TextField
                label="Nom du propriétaire *"
                placeholder="Nom du propriétaire *"
                fullWidth
                onChange={handleChange('lastName')}
                value={values.lastName}
                error={!!errors.lastName}
                variant="outlined"
                disabled={readOnly}
              />
              {!!errors && !!errors.firstName && (
                <FormHelperText sx={{ marginLeft: 0.5 }}>
                  {errors.firstName}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid xs={12} item rowGap={2} container flexDirection="column">
            {values.phones.map((phone, index) => (
              <Grid item container columnGap={1}>
                <Grid item flexGrow={1}>
                  <FormControl fullWidth error={!!errors.phones?.[index]}>
                    <TextField
                      label={`Numéro de téléphone (${index + 1}) *`}
                      placeholder={`Numéro de téléphone (${index + 1}) *`}
                      fullWidth
                      type="tel"
                      onChange={handleChange(`phones[${index}]`)}
                      value={values.phones[index]}
                      error={!!errors.phones?.[index]}
                      variant="outlined"
                      disabled={readOnly}
                    />
                    {errors && !!errors.phones?.[index] && (
                      <FormHelperText sx={{ marginLeft: 0.5 }}>
                        {errors.phones[index]}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item>
                  {index !== 0 ? (
                    <Button
                      variant="outlined"
                      color="error"
                      sx={{ height: 55, width: '100%' }}
                      onClick={() => {
                        const newPhones = [...values.phones];
                        newPhones.pop();
                        setFieldValue('phones', newPhones);
                      }}
                    >
                      <Clear />
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      sx={{ height: 55, width: '100%' }}
                      onClick={() =>
                        setFieldValue('phones', [...values.phones, ''])
                      }
                      disabled={readOnly}
                    >
                      <Add />
                    </Button>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </form>
    )}
  </Formik>
);

export default SalonDetailsForm;
