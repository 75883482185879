import React from 'react';

import axios from '../utils/axios';

const KpiContext = React.createContext({});

export const KpisProvider = ({ children }) => {
  const [numberSalons, setNumberSalons] = React.useState([]);
  const [numberClients, setNumberClient] = React.useState([]);
  const [numberAppointements, setNumberAppointements] = React.useState([]);

  const getCountSalon = async () => {
    const { data } = await axios.get('/v1/admin/kpi/count-salon');
    setNumberSalons(data.number);
    return data.number;
  };
  const getCountClient = async () => {
    const { data } = await axios.get('/v1/admin/kpi/count-client');
    setNumberClient(data.number);
    return data.number;
  };
  const getCountAppointement = async () => {
    const { data } = await axios.get('/v1/admin/kpi/count-appointement');
    setNumberAppointements(data.number);
    return data.number;
  };

  React.useEffect(() => {
    getCountSalon();
    getCountClient();
    getCountAppointement();
  }, []);
  return (
    <KpiContext.Provider
      value={{
        numberSalons,
        numberClients,
        numberAppointements
      }}
    >
      {children}
    </KpiContext.Provider>
  );
};

export const useKpis = () => React.useContext(KpiContext);
