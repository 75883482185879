import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import DeleteModal from 'src/components/DeleteModal';
import { useHistory, useRouteMatch } from 'react-router-dom';
import toastMessage from 'src/utils/toast';
import { DEFAULT_ERROR_MESSAGE } from 'src/utils/constants';
import SalonsResults from '../../components/salons/SalonsResults';
import SalonsToolbar from '../../components/salons/SalonsToolbar';
import { SalonsProvider, useSalons } from '../../contexts/SalonsContext';
import SalonsModal from '../../components/salons/SalonsModal';

const Salons = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const [addModal, setAddModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const { toDelete, setToDelete, deleteSalon } = useSalons();

  const closeDeleteModal = () => setDeleteModal(false);

  const onDelete = (row) => {
    setToDelete(row);
    setDeleteModal(true);
  };

  const onEdit = (id) => {
    history.push(`${match.url}/${id}`);
  };
  const onView = (id) => {
    history.push(`appointements/${id}`);
  };
  const submitDelete = () => {
    deleteSalon()
      .then((res) => {
        if (res) {
          setDeleteModal(false);
          return;
        }
        toastMessage("Aucun élément n'a été sélectionné.");
      })
      .catch((err) => {
        toastMessage(
          err?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
          'error'
        );
      });
  };

  return (
    <>
      <Helmet>
        <title>Salons | Izya</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <SalonsToolbar openAddModal={() => setAddModal(true)} />
          <Box sx={{ pt: 3 }}>
            <SalonsResults
              onDelete={onDelete}
              onEdit={onEdit}
              onView={onView}
            />
          </Box>
        </Container>
      </Box>
      <SalonsModal open={addModal} handleClose={() => setAddModal(false)} />
      <DeleteModal
        open={deleteModal}
        handleClose={closeDeleteModal}
        handleDelete={submitDelete}
        message={`Êtes vous sur de vouloir supprimer le salon "${toDelete?.name}"`}
      />
    </>
  );
};

export default () => (
  <SalonsProvider>
    <Salons />
  </SalonsProvider>
);
