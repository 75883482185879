import React from 'react';
import { Card, IconButton } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { Edit, DeleteOutline } from '@material-ui/icons';
import { useService } from 'src/contexts/ServiceContext';
// import Empty from 'src/assets/Empty';

const ServicesResults = ({ onEdit, onDelete, ...rest }) => {
  const { services, page, setPage, pageSize, totalCount, fetching, search } =
    useService();
  const [list, setList] = React.useState([]);

  React.useEffect(() => {
    if (search.trim()) {
      setList(
        services.filter((s) =>
          s.name.toLowerCase().startsWith(search.toLowerCase())
        )
      );
      return;
    }
    setList(services);
  }, [services, search]);

  const columns = [
    {
      field: 'name',
      headerName: 'Service',
      flex: 1
    },

    {
      field: 'actions',
      headerName: 'Actions',
      width: 135,
      renderCell: ({ row }) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            gap: 10
          }}
        >
          <IconButton onClick={() => onEdit(row)}>
            <Edit color="primary" />
          </IconButton>
          <IconButton onClick={() => onDelete(row)}>
            <DeleteOutline color="error" />
          </IconButton>
        </div>
      )
    }
  ];

  return (
    <Card {...rest} style={{ width: '100%', height: '60vh' }}>
      <DataGrid
        rows={list}
        columns={columns}
        pageSize={pageSize}
        page={page}
        onPageChange={(p) => setPage(p)}
        loading={fetching}
        paginationMode="server"
        isRowSelectable={false}
        rowCount={totalCount}
        disableSelectionOnClick
        getRowId={(row) => row._id || row.id}
      />
      {/* {
        services.length === 0 ? <Empty label="Aucun service !" /> :
        <DataGrid
          rows={list}
          columns={columns}
          pageSize={pageSize}
          loading={fetching}
          isRowSelectable={false}
          disableSelectionOnClick
        />
      } */}
    </Card>
  );
};

export default ServicesResults;
