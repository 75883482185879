import { FormControl, Grid, Typography } from '@material-ui/core';
import { Switch, TextField } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TimePicker from '@mui/lab/TimePicker';

import React from 'react';
import dayjs, { daysOfWeek } from 'src/utils/dayjs';
import { Formik } from 'formik';

const SalonOpenDatesForm = ({ onSubmit, formRef, data, readOnly }) => {
  const defaultDates = daysOfWeek('en').map((d) => ({
    isOpen: true,
    day: d,
    from: dayjs().set('hour', 9).minute(0),
    to: dayjs().set('hour', 17).minute(0)
  }));

  return (
    <Formik
      initialValues={{
        openDates:
          data && data.openDates?.length === 7 ? data.openDates : defaultDates
      }}
      onSubmit={onSubmit}
      innerRef={formRef}
    >
      {({ values, setFieldValue }) => (
        <Grid container flexDirection="column" rowGap={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {values.openDates.map((d, index) => (
              <Grid item columnGap={3} container alignItems="center">
                <Switch
                  defaultChecked={d.isOpen}
                  checked={d.isOpen}
                  onChange={(e) =>
                    setFieldValue(
                      `openDates[${index}].isOpen`,
                      e.target.checked
                    )
                  }
                  disabled={readOnly}
                />
                <Typography sx={{ width: 80 }}>{d.day}</Typography>

                <FormControl>
                  <TimePicker
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(t) =>
                      setFieldValue(`openDates[${index}].from`, t)
                    }
                    value={d.from}
                    clearable={false}
                    disabled={!d.isOpen || readOnly}
                  />
                </FormControl>
                <FormControl>
                  <TimePicker
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(t) => setFieldValue(`openDates[${index}].to`, t)}
                    value={d.to}
                    clearable={false}
                    disabled={!d.isOpen || readOnly}
                  />
                </FormControl>
              </Grid>
            ))}
          </LocalizationProvider>
        </Grid>
      )}
    </Formik>
  );
};

export default SalonOpenDatesForm;
