import {
  FormControl,
  FormHelperText,
  Grid,
  TextField
} from '@material-ui/core';
import { Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';

const SalonsSocialMediasForm = ({ onSubmit, formRef, data, readOnly }) => (
  <Formik
    initialValues={{
      instagramLink: data?.instagramLink || '',
      facebookLink: data?.facebookLink || ''
    }}
    validationSchema={yup.object().shape({
      instagramLink: yup.string().required('Champ obligatoire.'),
      facebookLink: yup.string().required('Champ obligatoire.')
    })}
    enableReinitialize
    innerRef={formRef}
    onSubmit={onSubmit}
  >
    {({ handleSubmit, values, handleChange, errors }) => (
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid xs={12} item>
            <FormControl fullWidth error={!!errors.instagramLink}>
              <TextField
                label="Instagram"
                placeholder="Instagram"
                fullWidth
                value={values.instagramLink}
                error={!!errors.instagramLink}
                onChange={handleChange('instagramLink')}
                disabled={readOnly}
              />
              {!!errors && !!errors.instagramLink && (
                <FormHelperText sx={{ marginLeft: 0.5 }}>
                  {errors.instagramLink}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid xs={12} item>
            <FormControl fullWidth error={!!errors.facebookLink}>
              <TextField
                label="Facebook"
                placeholder="Facebook"
                fullWidth
                value={values.facebookLink}
                error={!!errors.facebookLink}
                onChange={handleChange('facebookLink')}
                disabled={readOnly}
              />
              {!!errors && !!errors.facebookLink && (
                <FormHelperText sx={{ marginLeft: 0.5 }}>
                  {errors.facebookLink}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </form>
    )}
  </Formik>
);

export default SalonsSocialMediasForm;
