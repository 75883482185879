import dayjs from 'dayjs';

import 'dayjs/locale/en';
import 'dayjs/locale/fr';

export const daysOfWeek = (locale = 'en') => {
  const days = [];
  let counter = 0;
  while (counter < 7) {
    days.push(dayjs().locale(locale).set('day', counter).format('dddd'));
    counter += 1;
  }
  return days;
};

export default dayjs;
