import React from 'react';
import { DEFAULT_ERROR_MESSAGE } from 'src/utils/constants';
import toastMessage from 'src/utils/toast';
import axios from '../utils/axios';

const SpecialtyContext = React.createContext({});

export const SpecialtyProvider = ({ children }) => {
  const [specialties, setSpecialties] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [fetching, setFetching] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [pageSize] = React.useState(10);
  const [search, setSearch] = React.useState('');
  const [specialty, setSpecialty] = React.useState(null);
  const [toDelete, setToDelete] = React.useState(null);

  const fetchSpecialties = async () => {
    try {
      setFetching(true);
      const { data } = await axios.get(`/v1/specialties`);
      setFetching(false);
      setSpecialties(data);
      setTotalCount(data.length);
    } catch (error) {
      setFetching(false);
      toastMessage(
        error?.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        'error'
      );
    }
  };

  const addSpecialty = async (data) => {
    try {
      const { data: created } = await axios.post('/v1/admin/specialties', data);
      setSpecialties([...specialties, created]);
      return Promise.resolve(created);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const updateSpecialty = async (data) => {
    try {
      const { data: updated } = await axios.put(`/v1/admin/specialties`, {
        ...data,
        id: specialty._id
      });
      const index = specialties.findIndex((s) => s._id === specialty._id);
      if (index !== -1) {
        const newSpecialties = [...specialties];
        newSpecialties[index] = { ...newSpecialties[index], ...data };
        setSpecialties(newSpecialties);
      }
      setSpecialty(null);
      return Promise.resolve(updated);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const deleteSpecialty = async () => {
    try {
      await axios.delete(`/v1/admin/specialties/`, {
        data: { id: toDelete._id }
      });
      setSpecialties(specialties.filter((s) => s._id !== toDelete._id));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };
  React.useEffect(() => {
    fetchSpecialties();
  }, []);

  React.useEffect(() => {
    fetchSpecialties(search);
  }, [search]);

  return (
    <SpecialtyContext.Provider
      value={{
        specialties,
        fetching,
        fetchSpecialties,
        page,
        setPage,
        pageSize,
        totalCount,
        search,
        setSearch,
        specialty,
        setSpecialty,
        addSpecialty,
        updateSpecialty,
        deleteSpecialty,
        toDelete,
        setToDelete
      }}
    >
      {children}
    </SpecialtyContext.Provider>
  );
};

export const useSpecialty = () => React.useContext(SpecialtyContext);
