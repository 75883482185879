import React from 'react';
import { Card, IconButton } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { Edit, DeleteOutline } from '@material-ui/icons';
import { useAds } from 'src/contexts/AdsContext';
import Empty from 'src/assets/Empty';

const AdsResults = ({ onEdit, onDelete, ...rest }) => {

  const { publications, publication, totalCount, pageSize, fetching, search } =
    useAds();

  const [list, setList] = React.useState([]);

  React.useEffect(() => {
    if (search.trim()) {
      setList(
        publications.filter((s) =>
          s.nameEntreprise.toLowerCase().startsWith(search.toLowerCase())
        )
      );
      return;
    }
   
    setList(publications);
  }, [publications]);

  const columns = [
    {
      field: 'nameEntreprise',
      headerName: 'Name entreprise',
      flex: 1
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1
    },

    {
      field: 'actions',
      headerName: 'Actions',
      width: 135,
      renderCell: ({ row }) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            gap: 10
          }}
        >
          <IconButton onClick={() => onEdit(row)}>
            <Edit color="primary" />
          </IconButton>
          <IconButton onClick={() => onDelete(row)}>
            <DeleteOutline color="error" />
          </IconButton>
        </div>
      )
    }
  ];

  return list.length > 0 ? (
    <Card {...rest} style={{ width: '100%', height: '60vh' }}>
      {list === undefined ? (
        <Empty label="loading" />
      ) : (
        <DataGrid
          rows={list}
          columns={columns}
          pageSize={pageSize}
          loading={fetching}
          isRowSelectable={false}
          paginationMode="server"
          rowCount={totalCount}
          disableSelectionOnClick
          getRowId={(row) => row._id || row.id}
        />
      )}
    </Card>
  ) : (
    <></>
  );
};

export default AdsResults;
