import { useState } from 'react';
import { styled } from '@material-ui/core/styles';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import Salons from 'src/pages/Salons';
import CustomerList from 'src/pages/CustomerList';
import ads from 'src/pages/ads/ad';
import Specialties from 'src/pages/Specialties/Specialties';
import Services from 'src/pages/Services/Services';
import SubServices from 'src/pages/SubServices/SubServices';
import Kpis from 'src/pages/Kpis/Kpis';
import OurPix from 'src/pages/Our-pix/OurPix';
import Appointements from 'src/pages/Appointements';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';


const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}));

const DashboardLayoutWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 256
  }
}));

const DashboardLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const DashboardLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const match = useRouteMatch();
  return (
    <DashboardLayoutRoot>
      <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <DashboardSidebar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Switch>
              <Route path={`${match.path}/kpi`} component={Kpis} />
              <Route path={`${match.path}/our-pix`} component={OurPix} />
              <Route
                path={`${match.path}/appointements`}
                component={Appointements}
              />
              <Route path={`${match.path}/salons`} component={Salons} />
              <Route
                path={`${match.path}/specialties`}
                component={Specialties}
              />
              <Route path={`${match.path}/services`} component={Services} />
              <Route
                path={`${match.path}/prestation`}
                component={SubServices}
              />
              <Route path={`${match.path}/ads`} component={ads} />
              <Route path={`${match.path}/users`} component={CustomerList} />
              <Redirect exact from={match.path} to={`${match.path}/salons`} />
            </Switch>
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
